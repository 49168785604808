<template>
  <div class="agreement">
    <div class="container">
      <div
        class="list flex flex-vc"
        v-for="(item, index) in agreementArr"
        :key="index"
        @click="$router.push(item.path)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  computed: {
    agreementArr() {
      return this.$t("service.agreement.agreementArr");
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.agreement {
  padding-bottom: 52px;
  .list {
    height: 60px;
    background: var(--whiteColor);
    border-radius: 6px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 8px;
    color: var(--blue);
    padding: 0 32px;
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
